import { Box, 
  Grid,
  ThemeProvider, 
  Typography 
} from "@mui/material";
import React from "react";
import logo from '../StudioB.png';
import { motion as m } from 'framer-motion'
import theme from "../Styled Components/Theme";

function Acknowledgement () {
  return(<>
  <m.div
        initial={{ 
          y: 0,
        }}
        animate={{ 
          y: '-100vh',
          transition: { duration: 1, delay: 4 } 
        }}
    >
  <ThemeProvider theme={theme}>
  
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    minHeight="100vh"
    backgroundColor='primary.main'
  >
        <Grid container 
      // backgroundColor='lightskyblue' 
      m='auto' width='60%'>
        <Grid item textAlign='center' p='50px 0'>
          <img src={logo} alt="Logo" width="60%"/>
        </Grid>
        <Grid item width='80%' m='auto'>
          <Typography fontSize={{ xs: 15, sm: 20, md: 25, xl: 30 }} fontWeight={500} textAlign='center'>
            I acknowledge the traditional custodians of the land on which I live and learn, the Ngunnawal people of ACT and Bidjigal people of the Eora Nation. I acknowledge and respect their continuing culture and the contribution they make to the life of this region.
          </Typography>
        </Grid>
      </Grid> 
      </Box>   
     
  
  </ThemeProvider>
  </m.div>
  </>)
}

export default Acknowledgement;
