import React from 'react';
import './App.css';
import Home from './Components/Home';
import About from './Components/About';
import Contact from './Components/Contact';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation
} from "react-router-dom";
import {
  Box,
} from '@mui/material';
import { AnimatePresence} from "framer-motion";

import Seat from './Components/ProjectCards/Seat';
import Table from './Components/ProjectCards/Table';
import Pecks from './Components/ProjectCards/Pecks';
import Light from './Components/ProjectCards/WallLight';
import Oval from './Components/ProjectCards/Oval';
import Vessel from './Components/ProjectCards/Vessel';

import Footer from './Components/Footer';
import Navigation from './Components/Navigation';
import Acknowledgement from './Components/Acknowledgement';
import Hedge from './Components/ProjectCards/Hedge';

function LocationProvider({ children }) {
  return <AnimatePresence>{children}</AnimatePresence>;
}

function RoutesWithAnimation() {
  const location = useLocation();

  return (
    <Routes location={location} key={location.key}>
      
      <Route path='/yarramundi' element={<Table/>}/>
      <Route path='/seat' element={<Seat/>}/>
      <Route path='/clothespecks' element={<Pecks/>}/>
      <Route path='/walllight' element={<Light/>}/>
      <Route path='/oval' element={<Oval/>}/>
      <Route path='/vessel' element={<Vessel/>}/>
      <Route path='/hedge' element={<Hedge/>}/>
      
    <Route path='/contact' element={<Contact/>}/>
      <Route path='/about' element={<About/>}/>
      <Route exact path='/' element={<Home/>} />
      
    </Routes>
  );
}

function App() {

 
  // When refresh have a transition of acknolegement of traditional owners. keep on whatever page left on https://www.junglefy.com.au/product/junglefy-breathing-stand
  const [showAck, setShowAck] = React.useState(true);
  React.useEffect(() => {
    setShowAck(true);
    setTimeout(() => {
      setShowAck(false);
    }, 5000);
  }, [])


  return (
    <Router>
      <AnimatePresence>
      {showAck 
        ? <Box sx={{ margin: 0, display: 'flex', }}>
         <Acknowledgement/>
        </Box>
        : <>
      <Navigation/>
      <Box sx={{ minHeight: '100vh', margin: 0, display: 'flex', flexDirection: 'column', }}>
        <Box sx={{ mt: '80px', flex: 'auto' }}>
            
          <LocationProvider>
            <RoutesWithAnimation />
          </LocationProvider>
        </Box>
        <Box component='footer' sx={{
            py: 2,
            px: 0,
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[200]
                : theme.palette.grey[800],
          }}>
          <Footer />
        </Box>

      </Box>
      </>}
      
      </AnimatePresence>
    </Router>
  );
}

export default App;
